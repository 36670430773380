import * as turf from '@turf/turf';

import KML from 'ol/format/KML';
import GeoJSON from 'ol/format/GeoJSON';

import store from "@/store";

const abadiDownloadUtilities = () => {
    const downloadCertificate = () => {
        const ai_currentabadi = store.getters.ai_currentabadi;

        let a = document.createElement("a");
        a.href = ai_currentabadi.certbase64;;
        a.download = `MarkingCertificate-${ai_currentabadi.abadilimitname}-${ai_currentabadi.taluk}-${ai_currentabadi.district}.${ai_currentabadi.certextension}`;
        a.click();
    }

    const downloadAsKML = () => {
        const ai_currentabadi = store.getters.ai_currentabadi;
        console.log(ai_currentabadi.abadiFeat);

        let featGeomKML = new KML().writeFeatures([ai_currentabadi.abadiFeat], {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });
        // console.log(featGeomKML);

        let a = document.createElement("a");
        a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(featGeomKML);
        a.download = `${ai_currentabadi.abadilimitname}-${ai_currentabadi.taluk}-${ai_currentabadi.district}.kml`;
        a.click();
    }

    const downloadAsShp = () => {
        const ai_currentabadi = store.getters.ai_currentabadi;
        console.log(ai_currentabadi.abadiFeat);

        let featGeomGJ = new GeoJSON().writeFeatures([ai_currentabadi.abadiFeat], {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });

        // console.log(featGeomGJ);

        let gj = JSON.parse(featGeomGJ);
        let features = gj.features;

        let abadiFeatures = [];
        for (let i = 0; i < features.length; i++){
            let feature = features[i];
            abadiFeatures.push(feature);
        }

        let downloadfilename = `${ai_currentabadi.abadilimitname}-${ai_currentabadi.taluk}-${ai_currentabadi.district}`
        let shapefilename = downloadfilename;
        let foptions = {
            file: downloadfilename,
            folder: 'shapefile',
            types: {
                point: shapefilename,
                polygon: shapefilename,
                line: shapefilename,
                polyline: shapefilename
            },
            compression:'STORE'
        };

        let w = <any>window;
        w.shpwrite.download(gjmerge(abadiFeatures), foptions);
    }

    const gjmerge = (gjinputs: any) => {
        let output = <any>{
            type: 'FeatureCollection',
            features: []
        };

        for (let i = 0; i < gjinputs.length; i++) {
                let normalized = normalizegeojson(gjinputs[i]);
            for (let j = 0; j < normalized.features.length; j++) {
                output.features.push(normalized.features[j]);
            }
        }
        
        return output;
    }

    const normalizegeojson = (gj: any) => {
        let types = <any>{
            Point: 'geometry',
            MultiPoint: 'geometry',
            LineString: 'geometry',
            MultiLineString: 'geometry',
            Polygon: 'geometry',
            MultiPolygon: 'geometry',
            GeometryCollection: 'geometry',
            Feature: 'feature',
            FeatureCollection: 'featurecollection'
        };

        if (!gj || !gj.type) return null;
        let type = types[gj.type];
        if (!type) return null;

        if (type === 'geometry') {
            return {
                type: 'FeatureCollection',
                features: [{
                type: 'Feature',
                properties: {},
                geometry: gj
            }]};
        } else if (type === 'feature') {
            return {
                type: 'FeatureCollection',
                features: [gj]
            };
        } else if (type === 'featurecollection') {
            return gj;
        }
    }

    const downloadBufferedKML = (bufferValue: any) => {
        const ai_currentabadi = store.getters.ai_currentabadi;
        console.log(ai_currentabadi.abadiFeat);

        const geojsonReaderWrite = new GeoJSON();
        let featGeomGJ = geojsonReaderWrite.writeFeatures([ai_currentabadi.abadiFeat], {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });
        // console.log(featGeomKML);

        let bufferGJ = turf.buffer(JSON.parse(featGeomGJ), bufferValue, { units: 'meters' });
        let bufferedFeatures = <any>geojsonReaderWrite.readFeatures(bufferGJ);
        // console.log(bufferedFeature);

        let bufferedFeatGeomKML = new KML().writeFeatures(bufferedFeatures);
        // console.log(featGeomKML);

        let a = document.createElement("a");
        a.href = 'data:text/plain;charset=utf-8,' + encodeURIComponent(bufferedFeatGeomKML);
        a.download = `Buffered-${ai_currentabadi.abadilimitname}-${ai_currentabadi.taluk}-${ai_currentabadi.district}.kml`;
        a.click();  
    }

    const downloadBufferedShp = (bufferValue: any) => {
        const ai_currentabadi = store.getters.ai_currentabadi;
        console.log(ai_currentabadi.abadiFeat);

        const geojsonReaderWrite = new GeoJSON();
        let featGeomGJ = geojsonReaderWrite.writeFeatures([ai_currentabadi.abadiFeat], {
            dataProjection: 'EPSG:4326',
            featureProjection: 'EPSG:3857'
        });
        // console.log(featGeomKML);

        let bufferGJ = turf.buffer(JSON.parse(featGeomGJ), bufferValue, { units: 'meters' });
        let bufferedFeatures = <any>geojsonReaderWrite.readFeatures(bufferGJ);
        // console.log(bufferedFeature);

        let bufferedGJ = new GeoJSON().writeFeatures(bufferedFeatures);

        // console.log(bufferedGJ);

        let gj = JSON.parse(bufferedGJ);
        let features = gj.features;

        let abadiFeatures = [];
        for (let i = 0; i < features.length; i++){
            let feature = features[i];
            abadiFeatures.push(feature);
        }

        let downloadfilename = `Buffered-${ai_currentabadi.abadilimitname}-${ai_currentabadi.taluk}-${ai_currentabadi.district}`
        let shapefilename = downloadfilename;
        let foptions = {
            file: downloadfilename,
            folder: 'shapefile',
            types: {
                point: shapefilename,
                polygon: shapefilename,
                line: shapefilename,
                polyline: shapefilename
            },
            compression:'STORE'
        };

        let w = <any>window;
        w.shpwrite.download(gjmerge(abadiFeatures), foptions);
    }

    return { 
        downloadCertificate, downloadAsKML, downloadAsShp,
        downloadBufferedKML, downloadBufferedShp,
        gjmerge, normalizegeojson 
    }
}

export default abadiDownloadUtilities;